import React, { useEffect, useState } from "react";
import { Grid, Text } from "basis";
import {
  Container,
  ErrorMessage,
  LoadingMessage,
  PortalBackground,
} from "../../../components";
import {
  CustomerDetails,
  Footer,
  Pending,
  Cart,
  MerchantHeader,
} from "../components";
import Promotions from "../../../components/Promotions/Promotions";
import styled from "styled-components";
import { BREAKPOINT, COLOR } from "../../../components/theme";
import * as api from "../utils/api";
import { useQueryParam, StringParam } from "use-query-params";
import { logger } from "../../../core";
import { isBrowser } from "../../../utils";
import { useMerchantAuth } from "../../../core/auth";
import { MERCHANT_PAGE_ROUTES } from "../../../core/constants";

const StyledContainer = styled.div`
  border-radius: 10px;
  background-color: ${COLOR.WHITE};
  padding: 30px 15px;
  margin-top: 30px;

  @media (min-width: ${BREAKPOINT.SIZE_MOBILE}) {
    padding: 30px;
  }
  a {
    font-size: 15px;
    span {
      color: ${COLOR.DARK_GREY};
      border-color: ${COLOR.DARK_GREY};

      &:hover {
        border-color: ${COLOR.DARK_GREY};
        background: transparent;
      }
    }
  }
  .error {
    border-bottom: 5px solid ${COLOR.RED};
    display: block;
    width: 100%;
    padding: 15px 0;
    margin-bottom: 15px;

    p {
      color: ${COLOR.RED} !important;
    }
  }
`;

const Scene = () => {
  const [order, setOrder] = useState();
  const [transactionId] = useQueryParam("transactionId", StringParam);
  const [promotions, setPromotions] = useState();
  const [selectedPromotion, setSelectedPromotion] = useState();
  const [error, setError] = useState();
  const [submissionError, setSubmissionError] = useState();
  const [promoCode, setPromoCode] = useState();
  const [showPending, setPendingStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isPromotionSelected, setIsPromotionSelected] = useState(false);

  const { profile: merchantProfile } = useMerchantAuth();

  useEffect(() => {
    const getTransactionDetails = async () => {
      if (!transactionId) {
        setError(new Error("invalid-request"));
        return;
      }

      if (isBrowser() && window.location.hash === "#Link-Sent") {
        setPendingStatus(true);
      }

      try {
        const response = await api.getTransaction(transactionId);

        if (!response) {
          setError(new Error("invalid-request"));
          return;
        }

        const { selectedPromotion, promotions } = response;
        setOrder(response);
        setPromotions(promotions);
        setSelectedPromotion(selectedPromotion);
        setPromoCode(selectedPromotion.value);
        setLoading(false);
        if (promotions?.length === 1) {
          setIsPromotionSelected(true);
        }
      } catch (err) {
        logger.error(err.message);
        setError(new Error("invalid-request"));
      }
    };
    getTransactionDetails();
  }, [transactionId]);

  const onSubmit = async (e) => {
    setLoading(true);

    const { success } = await api.sendEncryptedLink({
      merchantId: merchantProfile?.merchantId,
      transactionId: transactionId,
      promotionReference: promoCode,
      email: order.customer.email,
      phoneNumber: order.customer.phone,
    });

    if (success) {
      setLoading(false);
      setPendingStatus(true);
      if (isBrowser()) {
        window.location.hash = "Link-Sent";
      }
      return;
    }
    setLoading(false);
    setSubmissionError(true);
    return;
  };

  const handlePromotionChange = (value) => {
    const matchingPromotion = promotions.find(
      (promotion) => promotion.value === value
    );

    setSelectedPromotion(matchingPromotion);
    setPromoCode(value);
    setIsPromotionSelected(true);
  };

  if (error) {
    return (
      <PortalBackground>
        <MerchantHeader />
        <ErrorMessage additionalParams={{
            urlCancelLabel: "Return to dashboard",
            urlCancel: MERCHANT_PAGE_ROUTES.OrderDashboard,
          }} />
      </PortalBackground>
    );
  }

  if (loading) {
    return <LoadingMessage />;
  }

  return (
    <PortalBackground>
      <MerchantHeader />
      <Container maxWidth="1000">
        <Grid preset="page" rowsGap={7}>
          <Grid.Item colSpan="all" colSpan-md="0-4" colSpan-lg="0-5">
            <StyledContainer>
              <Cart
                order={order}
                merchantDetails={merchantProfile}
                transactionId={transactionId}
              />
              <Footer order={order} />
            </StyledContainer>
          </Grid.Item>
          <Grid.Item colSpan="all" colSpan-md="5-12" colSpan-lg="6-12">
            <StyledContainer>
              {showPending ? (
                <Pending transactionId={transactionId} />
              ) : (
                <>
                  <Promotions
                    promotions={promotions}
                    selectedPromotion={selectedPromotion}
                    onChange={handlePromotionChange}
                    order={order}
                  />
                  <CustomerDetails
                    transactionId={transactionId}
                    order={order}
                    onSubmit={onSubmit}
                    isValid={isPromotionSelected}
                  />
                </>
              )}
              {submissionError && (
                <span className="form-totals error">
                  <Text>
                    An error has occured. Please check your selections and try
                    again.
                  </Text>
                </span>
              )}
            </StyledContainer>
          </Grid.Item>
        </Grid>
      </Container>
    </PortalBackground>
  );
};

export default Scene;
